.outer {
  background-color: white;
  margin: 20px auto;
  max-width: 1200px;
}
.container {
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.abouttext {
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  color: #62688b;
}
.Heading {
  color: #ffa500;
  margin-top: 24px;
}
.Heading1 {
  color: #ffa500;
  text-align: center;
  margin-bottom: 20px;
}
.flex {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}
.icons {
  color: #ffa500;
  font-size: 20px;
}
.listtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.aboutOuter {
  display: flex;
  align-items: start;
  gap: 20px;
}
.founderCard {
  width: 50%;
  border-radius: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
}
.founderImg {
  width: 100%;
  height: 600px;
  border-radius: 24px;
}
.w50 {
  width: 50%;
}
@media (min-width: 320px) and (max-width: 520px) {
  .outer {
    padding: 20px;
  }
  .Heading1 {
    font-size: 24px;
  }
  .Heading {
    font-size: 20px;
  }
  .aboutOuter {
    display: flex;
    flex-wrap: wrap;
  }
  .container {
    padding: 14px;
  }
  .founderCard {
    width: 100%;
  }
  .w50 {
    width: 100%;
  }
  .founderImg {
    width: 100%;
    height: 400px;
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .outer {
    padding: 20px 40px;
  }
  .aboutOuter {
    display: flex;
    flex-wrap: wrap;
  }
  .founderCard {
    width: 100%;
  }
  .w50 {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1240px) {
  .abouttext {
    font-size: 15px;
  }
}
