/* StarRating.module.css */
.starRating {
  display: flex;
  align-items: center;
}

.star {
  cursor: pointer;
  transition: color 0.2s;
}
