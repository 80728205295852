/* footer.module.css */

.footer {
  background: #333;
  color: #fff;
  padding: 20px 0;
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  max-width: 1200px;
  margin: auto;
}

.footerSection {
  flex: 1;
  margin: 10px;
}

.footerSection h4 {
  margin-bottom: 10px;
}

.footerLinks {
  list-style: none;
  padding: 0;
}

.footerLinks li {
  margin-bottom: 10px;
}

.footerLinks a {
  color: #fff;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

.footerBottom {
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 960px) {
  .footerContainer {
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 10px;
  }

  .footerSection {
    margin: 20px 0;
  }
}
.socialIocns {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Iocns {
  height: 24px;
  width: 24px;
  color: #ffa500;
}
