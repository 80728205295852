.imgs {
  height: 256px;
  width: 25%;
  border-radius: 8px;
  position: relative;
  margin: 0 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  animation: fall 3s ease forwards;
  opacity: 0;
  transform: translateY(-100%);
}
@keyframes fall {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .heading {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
  }
  .imgs {
    height: 200px;
    width: 93%;
    border-radius: 4px;
    position: relative;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .imgs {
    height: 220px;
    width: 95%;
    position: relative;
  }
}
@media (min-width: 769px) and (max-width: 1040px) {
  .heading {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .imgs {
    height: 200px;
    width: 90%;
    position: relative;
    margin: 0 12px;
  }

  .heading {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
@media (min-width: 1041px) and (max-width: 1440px) {
  .imgs {
    height: 250px;
    width: 90%;
    position: relative;
    margin: 0 12px;
  }

  .heading {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
  }
}
@media (min-width: 1441px) and (max-width: 3500px) {
  .imgs {
    height: 300px;
    width: 95%;
    position: relative;
    margin: 0 12px;
  }
  .headingouter {
    width: 95%;
    position: absolute;
    bottom: 33px;
    text-align: center;
    color: white;
    z-index: 1;
    padding: 0 16px;
    box-sizing: border-box;
    margin: 0 12px;
  }
}
