/* navBar.module.css */

.navbar {
  background: #333;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0px 250px;
  font-size: 1.2rem;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
}

.navbarLogo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.menuIcon {
  display: none;
}

.navMenu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-top: 60px;
}

.navLinks {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  position: relative;
}

.navItem {
  height: 80px;
}

.activeLink::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: #ffa500;
  border-radius: 50%;
}
.logo {
  width: 90px;
  height: 80px;
}
@media screen and (max-width: 960px) {
  .navbar {
    background: #333;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 20px;
  }
  .menuIcon {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffa500;
  }

  .navMenu {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    background: #333;
  }

  .navMenu.active {
    display: flex;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navItem {
    height: 60px;
  }
  .barbtn {
    color: white;
  }
  .menulist:hover {
    background-color: #333;
    color: white;
    border-radius: 10px;
  }
}

.barbtn {
  display: none;
}

.mobileMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  transition: all 0.3s ease;
  overflow-y: auto;
  width: 60%;
}

.mobileMenu.open {
  display: block;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  background-color: #333;
  color: white;
}

.Headerlogo {
  height: 40px;
}

.Headerclose {
  font-size: 1.8rem;
  cursor: pointer;
  color: #ffa500;
}

.mobileMenuContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.menulist {
  padding: 10px 0;
  text-decoration: none;
  color: #ffa500;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}

.menulist:hover {
  color: white;
  padding: 10px;
}
.menulist {
  padding: 10px;
}
.navbarLogosm {
  width: 60px;
  height: auto;
}
