.outer {
  background-color: white;
  max-width: 1200px;
  margin: 20px auto;
}
.container {
  background-color: #f2f2f2;
  padding: 24px;
  border-radius: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.contactUsContainer {
  font-family: Arial, sans-serif;
  color: #333;
}

.contactHeading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #ffa500;
}

.contactIntro {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  color: #62688b;
}

.contactDetails,
.addressSection,
.officeHours,
.contactForm,
.mapSection {
  margin-bottom: 30px;
}

.subHeading {
  font-size: 24px;
  font-weight: bold;
  color: #ffa500;
}

.contactText {
  font-size: 16px;
  line-height: 1.6;
  color: #62688b;
}

.form {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  margin-top: 16px;
  padding: 20px;
}

.formGroup {
  margin-bottom: 20px;
}

.formLabel {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.formInput,
.formTextarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
}
.formInput:focus {
  border: 1px solid #ffa500;
  outline: 1.5px solid #ffa500;
}
.formTextarea {
  height: 150px;
  resize: none;
}

.formButton {
  padding: 12px 20px;
  font-size: 18px;
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.formButton:hover {
  background-color: #ffa500;
}

.mapSection {
  margin-top: 40px;
}

.map {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 5px;
}
.flex {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;

  margin-bottom: 16px;
}
.icons {
  color: #ffa500;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .contactHeading {
    font-size: 28px;
  }

  .subHeading {
    font-size: 20px;
  }

  .contactIntro {
    font-size: 16px;
  }

  .formButton {
    font-size: 16px;
  }
}
@media (min-width: 320px) and (max-width: 520px) {
  .outer {
    padding: 20px;
  }
  .Heading1 {
    font-size: 24px;
  }
  .container {
    padding: 14px;
  }
  .Heading {
    font-size: 20px;
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .outer {
    padding: 20px 40px;
  }
}
