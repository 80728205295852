.heroSection {
  background-image: url("https://thumbor.forbes.com/thumbor/fit-in/1290x/https://www.forbes.com/advisor/wp-content/uploads/2022/09/Image_-_Law_Firm_Website_.jpeg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 690px;
  padding: 50px 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heroWidth {
  max-width: 1000px;
  margin: auto;
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.heroSection {
  font-size: 62px;
  font-weight: 700;
  color: #ffa500;
  text-align: center;
}

.heroSubhead {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-top: 32px;
}
.herotext {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: white;
  margin-top: 16px;
}
.mission {
  margin: 40px auto;
  max-width: 1200px;
}
.missionflex {
  display: flex;
  align-items: start;
  gap: 60px;
  height: auto;
  margin-top: 24px;
}
.MissionImgOuter {
  height: auto;
  width: 50%;
}
.missionImg {
  width: 100%;
  height: 100%;
}
.misHeading {
  font-size: 40px;
  font-weight: 800;
  color: #ffa500;
  text-align: center;
}
.missionGird {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.missionCard {
  width: 47%;
  height: 158px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.missionCard:hover {
  background-color: #ffa500;
  color: white !important;
}
.missionCard:hover .font40 {
  color: white;
}
.font40 {
  font-size: 40px;
  color: #ffa500;
  font-weight: 700;
}

.icons {
  color: #ffa500;
  font-size: 20px;
}
.silderHeading {
  font-size: 42px;
  font-weight: 700;
  color: #ffa500;
  text-align: center;
  margin: 24px auto 16px auto;
}
.case {
  margin: 40px auto;
  max-width: 1200px;
  padding: 20px;
  border-radius: 24px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}
.caseflex {
  display: grid;
  grid-template-columns: 23.73% 23.73% 23.73% 23.73%;
  gap: 20px;
}
.caseitem {
  padding: 20px;
  font-size: 20px;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffa500;
  background-color: white;
  cursor: pointer;
  width: 100%;
}
.caseitem.active {
  color: white;
  background-color: #ffa500;
}
.caseitem:hover {
  color: white;
  background-color: #ffa500;
}
.contentOuter {
  margin-top: 24px;
  background-color: white;
  padding: 16px;
  color: #62688b;
  font-size: 15px;
  border-radius: 8px;
  height: 175px;
  font-weight: 400;
}

.bookcontainer {
  margin: 40px auto;
  max-width: 1200px;
  display: flex;
  justify-content: start;
  gap: 30px;
  height: 600px;
}
.booksection {
  width: 60%;
  background-color: #f2f2f2;
  border-radius: 16px;
  padding: 20px;
}
.bookimgOuter {
  width: 40%;
}
.bookimgOuter img {
  width: 100%;
  height: 600px;
  border-radius: 16px;
  border: 2px solid #ffa500;
}

.booksubHEAD {
  font-size: 22px;
  color: #ffa500;
  font-weight: 600;
}
.bookHED {
  font-size: 24px;
  font-weight: 600;
  color: #62688b;
}
.BookDownload {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 214px;
  border: #ffa500;
  background-color: #ffa500;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  gap: 16px;
}
.fs15 {
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
}
@media (min-width: 320px) and (max-width: 520px) {
  .BookDownload {
    width: 100%;
  }
  .bookcontainer {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .fs15 {
    font-size: 13px;
    font-weight: 400;
  }
  .booksection {
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 16px;
    padding: 20px;
  }
  .bookimgOuter {
    width: 100%;
  }
  .caseflex {
    grid-template-columns: 100%;
  }
  .contentOuter {
    font-size: 14px;
    height: auto;
  }
  .case {
    margin: 20px;
    border-radius: 10px;
  }
  .silderHeading {
    font-size: 24px;
    font-weight: 700;
    color: #ffa500;
    text-align: center;
    margin: 24px auto 16px auto;
    width: 100%;
  }
  .mission {
    margin: 20px;
  }
  .missionCard {
    width: 100%;
  }
  .MissionImgOuter {
    height: auto;
    width: 100%;
  }
  .heroHeading {
    font-size: 32px;
  }
  .heroSection {
    padding: 20px;
  }
  .heroSubhead {
    font-size: 18px;
    margin-top: 16px;
  }
  .herotext {
    font-size: 14px;
  }
  .misHeading {
    font-size: 20px;
  }
  .missionflex {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .bookcontainer {
    margin: 20px 40px;
    height: auto;
    flex-wrap: wrap;
  }
  .booksection {
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 16px;
    padding: 20px;
  }
  .bookimgOuter {
    width: 100%;
  }

  .contentOuter {
    height: auto;
  }
  .caseflex {
    grid-template-columns: 48.5% 48.5%;
  }
  .case {
    margin: 20px 40px;
  }
  .mission {
    margin: 20px 40px;
  }
  .heroSection {
    padding: 20px 40px;
  }
  .heroHeading {
    font-size: 36px;
  }
  .heroSubhead {
    font-size: 18px;
    margin-top: 16px;
  }
  .herotext {
    font-size: 16px;
  }
  .misHeading {
    font-size: 32px;
  }
  .MissionImgOuter {
    height: auto;
    width: 100%;
  }
  .missionflex {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) and (max-width: 1240px) {
  li {
    font-size: 14px !important;
  }
  .caseitem {
    font-size: 16px;
  }
  .fs15 {
    font-size: 13px;
    font-weight: 400;
  }
  .caseflex {
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    gap: 20px;
  }
  .missionImg {
    height: 310px;
  }
}
